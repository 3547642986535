import $ from 'jquery';
import prestashop from 'prestashop';
$(window).load(function () {
  sldCustomChange();
});
$(document).ready(function () {
  createProductSpin();
  createInputFile();
  zoomImage();
  lightboxImage();
  coverImage();
  imageScrollBox();
  mobileImageScrollBox();
  moveProductAttributes();
  addAccordionActiveClass();
  moveProductPriceSticky();
  ogChangeSelection();

  $('body').on('change', '.product-variants *[name]', function () {
    togglePendingRefreshIcon('in');
  });

  prestashop.on('updatedProduct', function (event) {
    createInputFile();
    zoomImage();
    coverImage();
    imageScrollBox();
    mobileImageScrollBox();
    moveProductAttributes();
    changeSelection();

    if (event && event.product_minimal_quantity) {
      const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
      const quantityInputSelector = '#quantity_wanted';
      let quantityInput = $(quantityInputSelector);

      quantityInput.trigger('touchspin.updatesettings', {min: minimalProductQuantity});
    }
    
    $('#js_mfp_gallery').replaceWith(event.product_images_modal);
    lightboxImage();
    togglePendingRefreshIcon('out');

    if (typeof event.product_add_to_cart !== 'undefined') {
      if (event.product_add_to_cart.includes('add-to-cart-disabled')) {
        $('.product-add-to-cart').addClass('add-to-cart-disabled');
      } else {
        $('.product-add-to-cart').removeClass('add-to-cart-disabled');
      }
    }

  });

  // varCustomActionAddToCart
  handleAddToCart();  
  
});

var productResizeTimer;
$(window).on('resize', function() {
  clearTimeout(productResizeTimer);
  productResizeTimer = setTimeout(function() {
    zoomImage();
  }, 250);
});

function moveProductPriceSticky() {
  const mainPrice = document.querySelector('.product-right .js-product-prices');
  let priceSticky = document.querySelector('.sld-sticky-product-price');
  if (mainPrice !== null && priceSticky !== null) {
    priceSticky.innerHTML = '';
    let prices = mainPrice.cloneNode(true);
    if (prices.querySelector('#checkbox-delay-consent') !== null) {
      prices.querySelector('#checkbox-delay-consent').innerHTML = '';
    }
    priceSticky.append(prices);
  }
}
// overwrite 'add-to-cart'
function handleAddToCart() {
  $('body').off('click', '[data-button-action="add-to-cart"]');
  $('body').on('click', '[data-button-action="add-to-cart"]', function (event) {
    event.preventDefault();
    let $btn = $(this);

    if ($('#quantity_wanted').val() > $('[data-stock]').data('stock') && $('[data-allow-oosp]').data('allow-oosp').length === 0) {
      $btn.attr('disabled', 'disabled');
    } else {
      let $form = $(event.target).closest('form');
      let query = $form.serialize() + '&add=1&action=update';
      let actionURL = $form.attr('action');

      let isQuantityInputValid = function($input) {
        var validInput = true;

        $input.each(function (index, input) {
          let $input = $(input);
          let minimalValue = parseInt($input.attr('min'), 10);
          if (minimalValue && $input.val() < minimalValue) {
            onInvalidQuantity($input);
            validInput = false;
          }
        });

        return validInput;
      };

      let onInvalidQuantity = function($input) {
        $input.parents('.product-add-to-cart').first().find('.product-minimal-quantity').addClass('error');
        $input.parent().find('label').addClass('error');
      };

      let $quantityInput = $form.find('input[min]');
      if (!isQuantityInputValid($quantityInput)) {
        onInvalidQuantity($quantityInput);

        return;
      }

      $btn.removeClass('added').addClass('disabled');

      $.post(actionURL, query, null, 'json').then(function(resp) {
        if (typeof sld !== 'undefined' && !sld.isLoaded) {
          let time = 0;
          let intervalUpdateCart = setInterval(() => {
            if (sld.isLoaded) {
              prestashop.emit('updateCart', {
                reason: {
                  idProduct: resp.id_product,
                  idProductAttribute: resp.id_product_attribute,
                  idCustomization: resp.id_customization,
                  linkAction: 'add-to-cart',
                  cart: resp.cart
                },
                resp
              });
              clearInterval(intervalUpdateCart);
            }
            else if (time >= 3000) {
              clearInterval(intervalUpdateCart);
              location.reload();
            }
            time += 500;
          }, 500);
        } else {
          prestashop.emit('updateCart', {
            reason: {
              idProduct: resp.id_product,
              idProductAttribute: resp.id_product_attribute,
              idCustomization: resp.id_customization,
              linkAction: 'add-to-cart',
              cart: resp.cart
            },
            resp
          });
        }

        if (resp.success) {
          if (typeof(varPSAjaxCart) !== 'undefined' && varPSAjaxCart) {
            $btn.addClass('added');
          } else {
            window.location.href = prestashop.urls.pages.cart + '?action=show';
          }
        }
        //if (resp.hasError) {
          //$('.js-modal-message-text').text(resp.errors[0]);
          //$('.js-modal-message').modal('show');
        //}
      }).fail(function (resp) {
        prestashop.emit('handleError', { eventType: 'addProductToCart', resp });
      });
    }
  });
}

function togglePendingRefreshIcon(fade_status) {
  // varProductPendingRefreshIcon
  if (fade_status == 'in') {
    $('.js-product-refresh-pending-query').fadeIn();
  } else {
    $('.js-product-refresh-pending-query').fadeOut();
  }
}

function zoomImage() {
  $('#product .zoomWrapper .js-main-zoom').css('position','static').unwrap();
  $('#product .zoomContainer').remove();

  $('#product .js-enable-zoom-image .js-main-zoom').elevateZoom({
    zoomType: 'inner',
    gallery: 'js-zoom-gallery',
    galleryActiveClass: 'selected',
    imageCrossfade: true,
    cursor: 'crosshair',
    easing: true,
    easingDuration: 300,
    zoomWindowFadeIn: 500,
    zoomWindowFadeOut: 300,
  });
}

function lightboxImage() {
  const $gallery = $('#js_mfp_gallery');
  if ($gallery.length) {
    let zClose = $gallery.data('text-close'),
        zPrev = $gallery.data('text-prev'),
        zNext = $gallery.data('text-next');

    $('.js_mfp_gallery_item').magnificPopup({
      type: 'image',
      tClose: zClose,
      tLoading: '<div class="uil-spin-css"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>',
      removalDelay: 500,
      mainClass: 'mfp-fade',
      closeOnBgClick: true,
      gallery: {
        enabled: true,
        tPrev: zPrev,
        tNext: zNext,
        tCounter: '%curr% / %total%',
      },
      image: {
        verticalFit: false,
        titleSrc: 'data-title'
      }
    });

    $('.js-mfp-button').on('click', function() {
      //let imageId = $('#js-zoom-gallery .js-thumb.selected').data('id-image');
      $('.js_mfp_gallery_item').filter('[data-id-image="' + $('#js-zoom-gallery .js-thumb.selected').data('id-image') + '"]').trigger('click');
    });
  }
}

function coverImage() {
  $('#product .js-thumb').on(
    'click',
    function (event) {
      $('.js-thumb.selected').removeClass('selected');
      $(event.currentTarget).addClass('selected');
      $('.js-qv-product-cover').prop('src', $(event.currentTarget).data('image'));
      $('.product-cover .video-cover .video-container').html('');
      $('.product-cover .video-cover').hide();
    }
  );

  $('#product .thumb-video').on(
      'click',
      function (event) {
        $('.js-thumb.selected').removeClass('selected');
        $(event.currentTarget).addClass('selected');
        var embed = JSON.parse($(event.currentTarget).data('embed'));
        $('.product-cover .video-cover .video-container').html(embed);
        $('.product-cover .video-cover').show();
      }
  );
}

function imageScrollBox() {
  $('.js-product-thumbs-scrollbox').makeFlexScrollBox();
}
function mobileImageScrollBox() {
  $('.js-mobile-images-scrollbox').makeFlexScrollBox();
}


/*function showHideScrollBoxArrows() {
  let thumbsWidth = 0;
  $('.js-qv-product-images .js-thumb-container').each(function() {
    thumbsWidth = thumbsWidth + $(this).outerWidth();
  });

  if (($('.js-qv-product-images').width() + 5) < thumbsWidth) {
    $('.scroll-box-arrows').addClass('scroll');
  } else {
    $('.scroll-box-arrows').removeClass('scroll');
  }
}*/

function createInputFile() {
  $('.js-file-input').on('change', function(event) {
    let target, file;

    if ((target = $(event.currentTarget)[0]) && (file = target.files[0])) {
      $(target).prev().text(file.name);
    }
  });
}

function createProductSpin() {
  const $quantityInput = $('#quantity_wanted');
  $quantityInput.makeTouchSpin();

  $('body').on('change keyup', '#quantity_wanted', function (e) {
    $(e.currentTarget).trigger('touchspin.stopspin');
    prestashop.emit('updateProduct', {
      eventType: 'updatedProductQuantity',
      event: e
    });
  });
}

function moveProductAttributes() {
  let $src = $('.js-product-attributes-source'),
      $dest = $('.js-product-attributes-destination'),
      $src2 = $('.js-product-availability-source'),
      $dest2 = $('.js-product-availability-destination');
  $dest.empty();
  if ($src.length) {
    $dest.html($src.html()); //$src.remove();
  }
  $dest2.empty();
  if ($src2.length) {
    $dest2.html($src2.html()); //$src2.remove();
  }
}

function ogChangeSelection() {
  if (prestashop.page.page_name === 'product') {
    if ($('#product-details').data('product').attributes) {
      if ($('#product-details').data('product').attributes[1]) {
        console.log($('#product-details').data('products'));
        const $content = $('#product-details').data('product').attributes[1].name.toLowerCase();
        $('.product-variants-item-selection').removeAttr('hidden');
        $('.form-control-label-selection').find('strong').html($content.charAt(0).toUpperCase() + $content.slice(1));
      }
    }
  }
}

function changeSelection() {
  if ($('#product-details').data('product').attributes[1]) {
    const $content = $('#product-details').data('product').attributes[1].name.toLowerCase();
    $('.product-variants-item-selection').removeAttr('hidden');
    $('.form-control-label-selection').find('strong').html($content.charAt(0).toUpperCase() + $content.slice(1));
  }
}

function addAccordionActiveClass() {
  $('.js-product-accordions [data-toggle="collapse"]').on('click', function() {
    $(this).closest('.panel').toggleClass('active');
  });
}

function sldCustomChange(){
  var isOnDiv = false;
  if($('#colorize_groups').length > 0){
    $('#toggle_custom').removeClass('d-none');
    $('#colorize_groups').mouseenter(function(){
      isOnDiv=true;
    });
    $('#colorize_groups').mouseleave(function(){
      isOnDiv=false;
    });
    $('#colorize_groups').on('click', function(){
      if (isOnDiv) {
        sldShowCustomization();
      }
    });
  }
  $('#toggle_custom a').on('click', function(){
    if($('#toggle_custom a .customize').hasClass('d-none')){
      sldHideCustomization();
    }
    else{
      sldShowCustomization();
    }
  });
}
function sldShowCustomization(){
  $('#mainProduct .product-left-content-colorize').show();
  $('#mainProduct .product-left-content').hide();
  $('#toggle_custom a .customize').addClass('d-none');
  $('#toggle_custom a .display_view').removeClass('d-none');
  $(window).trigger('resize');
}
function sldHideCustomization(){
  $('#mainProduct .product-left-content-colorize').hide();
  $('#mainProduct .product-left-content').show();
  $('#toggle_custom a .display_view').addClass('d-none');
  $('#toggle_custom a .customize').removeClass('d-none');
  $(window).trigger('resize');
}