import soledis from './global';

const stickyProductNavbar = () => {
  let stickyObserver = document.querySelector('.js-product-add-to-cart');
  let stickyContainer = document.querySelector('.sld-js-sticky-product');
 
  if (stickyObserver !== null && stickyContainer !== null) {
    const options = {
      rootMargin: '0px',
      threshold: 1.0
    }
    
    const callback = (entries) => {
      if (stickyContainer !== null) {
        if (entries[0].isIntersecting) {
          stickyContainer.classList.remove('is-sticky');
        }
        else {
          stickyContainer.classList.add('is-sticky');
        }
      }
    }
    
    const observerStickyProduct = new IntersectionObserver(callback, options);
  
    observerStickyProduct.observe(stickyObserver);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  stickyProductNavbar();
});
window.addEventListener('load', () => {
    sld.isLoaded = true;
    console.log('loaded');
});