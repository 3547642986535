function updateDropdownPosition()
{
    var $amegamenu = $('#amegamenu');
    if ($amegamenu.length) {
        var updatePosition = function() {
            if ($amegamenu.hasClass('amegamenu_rtl')) {
                updateDropdownPositionRTL($amegamenu);
            }
            else {
                updateDropdownPositionLTR($amegamenu);
            }
        };

        updatePosition();
        $(window).on('resize', updatePosition);
    }
}

function updateDropdownPositionLTR(mm)
{
  var m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function() {
    let t = $(this),
        p = t.parent('.amenu-item'),
        i = 0 - (t.outerWidth() - p.outerWidth())/2,
        t_right = t.offset().left + t.outerWidth(),
        p_left = p.offset().left,
        margin = parseInt(t.css('margin-left'));
          
    if ( t_right - margin + i > m_right) {
      t.css('margin-left', (m_right - p_left - t.outerWidth()) + 'px');
    } else if (t.offset().left - margin + i < m_left) {
      if(window.innerWidth < 1603){
        t.css('margin-left', (m_left - p_left)- 10 + 'px');
      }else{
        t.css('margin-left', (m_left - p_left) + 'px');
      }
    } else {
      t.css('margin-left', i + 'px');
    }
  });
}
function updateDropdownPositionRTL(mm)
{
  var m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function() {
    let t = $(this),
        p = t.parent(),
        i = 0 - (t.outerWidth() - p.outerWidth())/2,
        t_right = t.offset().left + t.outerWidth(),
        p_right = p.offset().left + p.outerWidth(),
        margin = parseInt(t.css('margin-right'));
  
    if (t.offset().left + margin - i < m_left) {
      t.css('margin-right', (0 - (t.outerWidth() - p_right + m_left)) + 'px');
    } else if (t_right + margin - i > m_right) {
      t.css('margin-right', (0 - (m_right - p_right)) + 'px');
    } else {
      t.css('margin-right', i + 'px');
    }
  });
}

function enableHoverMenuOnTablet()
{
    $('html').on('touchstart', function(e) {
        $('#amegamenu .amenu-item').removeClass('hover');
    });
    $('#amegamenu').on('touchstart', function (e) {
        e.stopPropagation();
    });
    $('#amegamenu .amenu-item.has-submenu > .amenu-link').on('touchstart', function (e) {
        'use strict'; //satisfy code inspectors        
        var li = $(this).parent('li');
        if (li.hasClass('hover')) {
            return true;
        } else {
            $('#amegamenu .amenu-item').removeClass('hover');
            li.addClass('hover');
            e.preventDefault();
            return false;
        }
    });
}

function ajaxLoadDrodownContent() {
    var $ajaxmenu = $('.js-ajax-mega-menu');
    if ($ajaxmenu.length) {
        $.ajax({
            type: 'POST',
            url: $ajaxmenu.data('ajax-dropdown-controller'),
            data: {
                ajax: true,
            },
            dataType: 'json',
            success: function(data) {
                updateDrodownContent(data);
                // if mobile
                if (document.querySelector('#mobile-amegamenu') !== null) {
                    sldMenuResponsive(true);
                } // if desktop
                else {
                    // init page
                    sldBreakWidth();

                }
            },
            error: function(err) {
                console.log(err);
            }
        });

        var updateDrodownContent = function(dropdown) {
            $('.js-dropdown-content', $ajaxmenu).each(function () {
                let item = $(this).data('menu-id');
                $(this).replaceWith(dropdown[item]);
            });
        };
    }
}

function sldBreakWidth() {
    if (window.innerWidth < 1380 && !document.body.classList.contains('sld-menu-responsive')) {
        sldMoveMenuDesktop(true);
        document.body.classList.add('sld-menu-responsive');
    }
    else if (window.innerWidth >= 1380 && document.body.classList.contains('sld-menu-responsive')) {
        sldMoveMenuDesktop(false);
        document.body.classList.remove('sld-menu-responsive');
    }
}

function sldMoveMenuDesktop(activeMenuMobile) {
    const containerMain = document.querySelector('.header-main-menu-wrapper');
    const containerLeft = document.querySelector('.sidebar-menu');
    const menu = document.querySelector('#amegamenu');
    if (containerMain !== null && containerLeft !== null && menu !== null) {
        // if column left not contains menu
        if (containerLeft.querySelector('#amegamenu') === null && activeMenuMobile) {
            containerLeft.appendChild(menu);
            sldMenuResponsive(true);
        }
        // if header main not contains menu
        else if (containerMain.querySelector('#amegamenu')=== null && !activeMenuMobile) {
            containerMain.appendChild(menu);
            sldMenuResponsive(false);
        }
    }
}

function sldMenuResponsive(active) {
    if (active) {
        sldBackLink();
        sldSubMenuSlide();
    }
    else {
        sldDisableSubMenuSlide();
    }
}

function sldBackLink() {
    let menuContainer = document.querySelector('.st-menu .anav-top');
    let itemsContainer = document.querySelectorAll('.dropdown-content:first-child, .sld-menu-container-items, .sld-category-subs');
    let linksBack = document.querySelector('.sld-js-menu-back-button');
    if (itemsContainer.length > 0 && linksBack === null) {
        itemsContainer.forEach(function (container) {
            const item = sldCreateButtonBack();
            item.addEventListener('click', function (e) {
                e.preventDefault();
                let submenu = this.closest('ul');
                let submenuParent = submenu.parentElement.closest('ul');
                if (submenuParent.classList.contains('anav-top')) {
                    menuContainer.style.height = '';
                }
                else {
                    menuContainer.style.height = submenuParent.offsetHeight + 'px';
                }
                submenu.classList.remove('active');
            });
            container.prepend(item);
        });
    }
}

function sldCreateButtonBack() {
    let icon = document.createElement('span');
    icon.classList.add('material-icons');
    icon.innerText = 'keyboard_arrow_left';
    let button = document.createElement('button');
    button.classList.add('sld-js-menu-back-button');
    button.append(icon);
    button.append(document.createTextNode('retour'));
    let item = document.createElement('li');
    item.classList.add('sld-menu-back-link-mobile');
    item.append(button);
    return item;
}

function sldSubMenuSlide() {
    let buttonSubmenu = document.querySelectorAll('.sld-js-menu-submenu-button');

    if (buttonSubmenu.length > 0) {
        buttonSubmenu.forEach(function(button, key) {
            button.addEventListener('click',sldActiveSubmenu, true);
        });
    }
}

function sldDisableSubMenuSlide() {
    let buttonSubmenu = document.querySelectorAll('.sld-js-menu-submenu-button');
    if (buttonSubmenu.length > 0) {
        buttonSubmenu.forEach(function (button) {
            button.removeEventListener('click', sldActiveSubmenu, true);
        });
    }
}

function sldActiveSubmenu(event) {
    event.preventDefault();
    let menuContainer = document.querySelector('.st-menu .anav-top');
    let submenu = this.nextElementSibling
    if (submenu !== null) {
        submenu.classList.add('active');
        menuContainer.style.height = submenu.offsetHeight + 'px';
    }
}

function sldDisableSubmenu(button,menuContainer) {
    let submenu = button.closest('ul');
    if (submenu !== null) {
        submenu.classList.remove('active');
        if (submenu.parentElement.closest('ul').classList.contains('anav-top')) {
            menuContainer.style.height = 'auto';
        }
        else {
            menuContainer.style.height = submenu.parentElement.closest('ul').offsetHeight + 'px';
        }
    }
}

$(window).load(function() {
    setTimeout(function() {
        ajaxLoadDrodownContent();
        updateDropdownPosition();
    }, 600);
    // enableHoverMenuOnTablet();
});

// event resize
window.addEventListener('resize', function (e) {
    sldBreakWidth();
});
